<template>
  <!-- 弃用页面 -->
  <div class="userLogin">
    <new-title url="/" title="" />
    <div class="body1Style">
      <div class="pageTitle">
        注册新的 <br />
        Msr账号
      </div>
      <div class="bodyStyleDivStyle">
        <p class="bodyStyleDivStyleP" v-if="loginType === 'number'">
          <van-dropdown-menu active-color="#5B54EA">
            <van-dropdown-item v-model="value1" :options="option1" />
          </van-dropdown-menu>
        </p>
        <p class="bodyStyleDivStyleP" v-else>{{ phoneTypeText.title }}</p>
        <input
          v-model="phone"
          class="body1StyleInputStyle"
          :type="loginType"
          :placeholder="phoneTypeText.placeholder"
        />
      </div>
    </div>
    <div class="bottom1">
      <p class="agree">
        注册即代表您同意 <a href="javascript:;">用户协议</a> 和
        <a href="javascript:;">隐私政策</a>
      </p>
      <p @click="switchLoginType">
        <span v-if="loginType === 'number'">使用邮箱注册</span>
        <span v-else>使用手机号注册</span>
      </p>
      <van-row type="flex" justify="space-around">
        <van-col span="24">
          <van-button
            class="btnStyle mainBackColor1"
            @click="doLogin"
            type="primary"
            >注册账号</van-button
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import NewTitle from "../../components/title";

export default {
  name: "register",
  components: { NewTitle },
  data() {
    return {
      sms: "", // 短信验证码
      flag: false, // 获取验证码 是否禁用
      count: 60, // 60s 倒计时
      timer: null, // 倒计时的 定时器
      sid: "", // 验证
      bindPhone: false,
      openid: "",
      email: "",

      phone: "", // 手机号
      password: "", // 密码
      isShowPwd: false, // 是否显示密码
      loginType: "number",
      isSubmit: false,
      value1: "86",
      refcode: this.$route.query.refcode,
    };
  },
  created() {},
  watch: {
    option1(val) {
      if (val && val.length) {
        this.value1 = "86";
      }
    },
  },
  computed: {
    phoneTypeText() {
      this.phone = "";
      this.password = "";
      if (this.loginType === "number") {
        return {
          title: "手机号",
          placeholder: "请输入您的手机号",
        };
      }
      return {
        title: "邮箱",
        placeholder: "请输入您的邮箱",
      };
    },
    phoneCode() {
      return this.$t("phoneCode");
    },
    option1() {
      let arr = [];
      if (this.phoneCode) {
        const phoneCodeKey = Object.keys(this.phoneCode);
        phoneCodeKey.forEach((element) => {
          if (this.phoneCode[element]) {
            this.phoneCode[element].forEach((item) => {
              const data = item.split("+");
              arr.push({
                text: `${data[0]} +${data[1]}`,
                value: data[1],
              });
            });
          }
        });
      }
      return arr;
    },
  },
  methods: {
    switchLoginType() {
      this.loginType = this.loginType === "number" ? "email" : "number";
    },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.push("uSet");
    },
    // 提示
    showAlert(msg) {
      return this.$dialog.alert({
        title: "提示",
        message: msg,
      });
    },
    emailReg(email) {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (reg.test(email)) {
        return true;
      }
      return false;
    },
    // 验证
    check() {
      if (
        this.phone == "" ||
        (this.loginType === "email" && !this.emailReg(this.phone)) ||
        (this.loginType === "number" && this.phone.length !== 11)
      ) {
        this.showAlert(`请输入正确的${this.phoneTypeText.title}`);
        return false;
      }

      this.phone.trim(); // 去除一下两端空格
      return true;
    },
    // 注册
    doLogin() {
      if (!this.check()) return;
      if (this.isSubmit) return;
      this.isSubmit = true;
      let url = "/security/index/phonecode";
      let parameter = {
        phone: this.phone,
		nation: this.value1
      };
      if (this.loginType === "email") {
        url = "/security/index/emailcode";
        parameter = {
          email: this.phone,
        };
      }
      this.$post2(url, parameter)
        .then((res) => {
          this.$toast.success("验证码发送成功");
          this.sid = res.sid;
          this.isSubmit = false;
          setTimeout(() => {
            this.$router.push({
              path: "/verificationCode",
              query: {
                account: this.phone,
                loginType: this.loginType,
                type: "reg",
                code: this.value1,
                date: Date.parse(new Date()),
                sidVal: this.sid,
                refcode: this.refcode,
              },
            });
          }, 1000);
        })
        .catch((e) => {
          this.isSubmit = false;
          this.showAlert(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.userLogin {
  .newTitleTwo {
    background: #fff;
  }

  /* @import "../../assets/css/puliceStyle.css"; */

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }

  .pageTitle {
    font-size: 0.48rem;
    font-weight: Bold;
    line-height: 0.78rem;
    margin-bottom: 1.2rem;
  }

  .isShowPwd img {
    width: 100%;
    height: 100%;
  }

  .body1StyleInputStyle {
    border: 0px;
    font-size: 0.28rem;
    width: 80%;
    line-height: 0.8rem;
  }

  .bodyStyleDivStyleP {
    margin: 0 0 0.2rem 0;
    line-height: 48px;
  }

  .bodyStyleDivStyle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.1rem;
  }

  .body1Style {
    padding: 0.3rem;
  }

  .bottom1 {
    position: fixed;
    width: 100%;
    bottom: 0.2rem;
  }

  .bottom1 p {
    margin-left: 0.3rem;
    font-size: 0.26rem;
    color: #333;
  }

  .bottom1 .agree {
    color: #a2a2a2;
    font-size: 0.26rem;
    margin-bottom: 0.4rem;
  }

  .bottom1 .agree a {
    color: #000;
  }
}
</style>
